import {updateMetrics} from "../../redux/MetricsSlice";
import Scream from "./scream"
import VideoChunkProcessor from "./VideoChunkProcessor";

const ConnectReq = "connect_request"
const ConnectRes = "connect_response"

const STATUSES = {
  uninitialized: "not initialized",
  requested: "Requesting connection",
  accepted: "host accepted request",
  rejected: "host rejected request",
  streamInit: "requested stream initialization",
  offerReceived: "received offer",
  answerSent: "answer sent",
  offerSent: "offer sent",
  answerReceived: "answer received",
  connected: "connected"
}

const MsgTyp = {
  InitStream: "init_stream",
  RequestOffer: "request_offer",

  HostOffer: "host_offer",
  HostAnswer: "host_answer",
  HostCandidate: "host_candidate",

  ClientAnswer: 'client_answer',
  ClientOffer: 'client_offer',
  ClientReOffer: "client_reoffer",
  ClientCandidate: "client_candidate",

  ErrorMsg: "error"

}


class WebrtcClient {
  hostID;
  wsClient;
  peerConn;
  #target;
  timer;
  #status = STATUSES.uninitialized;
  scream;

  lastCursor = null;

  onCursorChanged;
  onCursorVisibilityChanged;
  lastCursorVisibility;

  onStatusChanged;
  onInputReady;

  videoChannel;
  inputChannel;
  controlChannel;
  audioChannel;

  videoProcessor;

  candidates = [];
  ontrack;

  bytesReceived = 0;
  bitrateTimer = null;

  latency = null;
  latencyTimer = null;

  frameCount = 0;
  fpsTimer = null;

  latencyWindow = [];

  totalEncodeDuration = 0;
  metadataCount = 0
  maxAccumulatedFrames = 0;
  encDurationTimer = null

  downloadSpeedTimer = null
  downloadSpeedSum = 0;
  downloadSpeedCount = 0;

  constructor(hostID, wsClient, statusChangeHandler, onInputReady, config, dispatch) {
    this.onStatusChanged = statusChangeHandler
    this.onInputReady = onInputReady
    this.#target = {type: "host", id: hostID}
    this.hostID = hostID;
    this.wsClient = wsClient
    wsClient.setMsgHandler(hostID, this._msgHandler.bind(this))
    this.send(ConnectReq, {version: "0.1"})
    this.status = STATUSES.requested
    this.timer = setTimeout(() => {
      this.destroy()
    }, 10000)

    this.scream = new Scream();
    this.videoProcessor = new VideoChunkProcessor(this.scream, this);

    this.videoProcessor.onBadFrame = () => {
      this.videoChannel.send("pli")
    }
    this.videoProcessor.onMetadata = (metadata) => {
      this.totalEncodeDuration += metadata.encode_duration;
      this.metadataCount++;
      this.maxAccumulatedFrames = Math.max(this.maxAccumulatedFrames, metadata.accumulated_frames);

      if ((metadata.pointer_visible !== this.lastCursorVisibility) && (metadata.last_mouse_update_time > 0)) {
        this.onCursorVisibilityChanged && this.onCursorVisibilityChanged(metadata.pointer_visible)
        this.lastCursorVisibility = metadata.pointer_visible;
      }
    }

    this.config = config;
    this.dispatch = dispatch;
    this.startMetricMeasurement();
  }

  get status() {
    return this.#status
  }

  set status(status) {
    this.#status = status;
    this.onStatusChanged && this.onStatusChanged(status)
  }

  set onVideoFrameReceived(handler) {
    this.videoProcessor.onVideoFrame = handler
  }

  send(msgType, payload) {
    this.wsClient.send({type: msgType, target: this.#target, payload})
  }

  #ontrack(e) {
    this.audioChannel = e.streams[0];
    this.ontrack && this.ontrack(e);
  };

  addIceCandidate(payload) {
    if (payload) {
      try {
        const candidate = new RTCIceCandidate(payload);
        this.candidates.push(candidate)
      } catch (e) {
        console.log(e)
      }
    }

    if (this.candidates.length > 0 && this.peerConn && this.peerConn.remoteDescription) {
      this.candidates.forEach(c => {
        this.peerConn.addIceCandidate(c)
      })
      this.candidates = []
    }
  }

  #videoDataReceived(packet) {
    this.bytesReceived += packet.byteLength;
    this.videoProcessor.push(packet);
  }

  measureEncodeDurationAndAccFrames() {
    this.encDurationTimer = setInterval(() => {
      let averageEncodeDuration;
      if (this.metadataCount > 0) {
        averageEncodeDuration = this.totalEncodeDuration / this.metadataCount;
      }

      if (averageEncodeDuration)
        this.dispatch(updateMetrics({type: 'encodeDuration', value: averageEncodeDuration.toFixed(2)}));
      if (this.maxAccumulatedFrames)
        this.dispatch(updateMetrics({type: 'accumulatedFrames', value: this.maxAccumulatedFrames}));

      this.totalEncodeDuration = 0;
      this.metadataCount = 0;
      this.maxAccumulatedFrames = 0;
    }, 1000);
  }

  measureBitrate() {
    if (this.bitrateTimer) {
      clearInterval(this.bitrateTimer);
    }

    this.bitrateTimer = setInterval(() => {
      const bitrate = (this.bytesReceived * 8) / 1024 / 1024;

      if (this.dispatch) {
        this.dispatch(updateMetrics({type: 'bitrate', value: bitrate.toFixed(2)}));
      }
      this.bytesReceived = 0;
    }, 1000);
  }

  measureLatency() {
    if (this.latencyTimer) {
      clearInterval(this.latencyTimer);
    }
    this.latencyTimer = setInterval(() => {
      this.sendPing();
    }, 1000);

    this.handleControlMessage = (msg) => {
      const message = String.fromCharCode.apply(null, new Uint8Array(msg));

      if (message.startsWith("ping ")) {
        const time = parseInt(message.substring(5), 10);
        this.latency = (Date.now() - time) / 2;

        this.latencyWindow.push({
          timestamp: Date.now(),
          latency: this.latency
        });

        if (this.dispatch) {
          this.dispatch(updateMetrics({type: 'latency', value: this.latency}));
        }
      }
    };
  }

  sendPing() {
    if (this.controlChannel && this.controlChannel.readyState === "open") {
      this.controlChannel.send("ping " + Date.now());
    }
  }

  measureFPS() {
    if (this.fpsTimer) {
      clearInterval(this.fpsTimer);
    }

    this.fpsTimer = setInterval(() => {
      const fps = this.frameCount;

      if (this.dispatch) {
        this.dispatch(updateMetrics({type: 'fps', value: fps}));
      }

      this.frameCount = 0;
    }, 1000);
  }


  measureJitter() {
    if (this.jitterTimer) {
      clearInterval(this.jitterTimer);
    }

    this.jitterTimer = setInterval(() => {
      const now = Date.now();
      const lastFiveSecLatency = this.latencyWindow.filter((latency) => (now - latency.timestamp <= 5000))
      this.latencyWindow = lastFiveSecLatency;

      if (this.latencyWindow.length > 0) {
        let maxLatency = -Infinity;
        let minLatency = Infinity;
        this.latencyWindow.map((lat) => {
          maxLatency = Math.max(maxLatency, lat.latency)
          minLatency = Math.min(minLatency, lat.latency)
        })

        const jitter = maxLatency - minLatency;

        if (this.dispatch) {
          this.dispatch(updateMetrics({type: 'jitter', value: jitter.toFixed(2)}))
        }
      }

    }, 5000)
  }

  updateDownloadSpeed(downloadSpeed) {
    this.downloadSpeedSum += downloadSpeed;
    this.downloadSpeedCount++;
  }

  measureDownloadSpeed() {
    if (this.downloadSpeedTimer) {
      clearInterval(this.downloadSpeedTimer);
    }

    this.downloadSpeedTimer = setInterval(() => {
      if (this.downloadSpeedCount > 0) {
        const averageSpeed = this.downloadSpeedSum / this.downloadSpeedCount;

        if (this.dispatch) {
          this.dispatch(updateMetrics({
            type: 'downloadSpeed',
            value: averageSpeed.toFixed(2)
          }));
        }

        this.downloadSpeedSum = 0;
        this.downloadSpeedCount = 0;
      }
    }, 1000);

  }

  startMetricMeasurement() {
    this.measureEncodeDurationAndAccFrames();
    this.measureBitrate();
    this.measureLatency();
    this.measureFPS();
    this.measureJitter();
    this.measureDownloadSpeed();
  }

  stopMetricsMeasurement() {
    clearInterval(this.encDurationTimer);
    this.encDurationTimer = null;

    clearInterval(this.bitrateTimer);
    this.bitrateTimer = null;

    clearInterval(this.latencyTimer);
    this.latencyTimer = null;

    clearInterval(this.fpsTimer);
    this.fpsTimer = null;

    clearInterval(this.jitterTimer);
    this.jitterTimer = null;

    clearInterval(this.downloadSpeedTimer);
    this.downloadSpeedTimer = null;
  }

  startPC(offer) {
    this.status = STATUSES.offerReceived
    let peerconn = new RTCPeerConnection({
      iceServers: [{urls: ["stun:stun.l.google.com:19302", "stun:stun1.l.google.com:19302", "stun:stun2.l.google.com:19302", "stun:stun3.l.google.com:19302"]}]
    });
    this.peerConn = peerconn
    peerconn.onicecandidate = ({candidate}) => {
      this.send(MsgTyp.ClientCandidate, candidate)
    }
    peerconn.onnegotiationneeded = async () => {
      try {
        if (peerconn.signalingState !== "stable" || !peerconn.localDescription) return;
        let offer = await peerconn.createOffer();
        console.log("negotiation needed!!!!")
        offer = new RTCSessionDescription(offer);
        await peerconn.setLocalDescription(offer);
        this.send(MsgTyp.ClientReOffer, peerconn.localDescription);
      } catch (err) {
        console.log(err);
      }
    }

    peerconn.ontrack = this.#ontrack.bind(this);
    peerconn.ondatachannel = ev => {
      switch (ev.channel.label) {
        case "video":
          ev.channel.onopen = () => {
            this.videoChannel = ev.channel
          }
          ev.channel.onmessage = (ev) => {
            this.#videoDataReceived(ev.data)
          }
          break;
        case "control":
          ev.channel.onopen = () => {
            this.controlChannel = ev.channel
          }
          ev.channel.onmessage = (ev) => {
            try {
              this.scream.add_description(ev.data)
            } catch (e) {
              this.handleControlMessage(ev.data)
            }
          }
          break;
        case "input":
          ev.channel.onopen = () => {
            this.inputChannel = ev.channel
            this.onInputReady && this.onInputReady();
          }
          ev.channel.onmessage = (ev) => {
            try {
              this.lastCursor = this.scream.parse(ev.data);
              this.#onCursor(this.lastCursor);
            } catch (e) {
              console.error("failed to parse scream", e)
            }
          }
          break;
        default:
          console.error("unexpected channel received.", ev.channel.label)
      }
    }
    peerconn.oniceconnectionstatechange = (e) => {
      this.status = e.target.iceConnectionState;
    }
    let f;
    if (offer) {

      f = async () => {
        try {
          await peerconn.setRemoteDescription(offer)
          let ans = await peerconn.createAnswer()
          await peerconn.setLocalDescription(ans)
          this.send(MsgTyp.ClientAnswer, peerconn.localDescription)
          this.addIceCandidate(peerconn)
          this.status = STATUSES.answerSent
        } catch (e) {
          console.error(e)
          this.destroy()
        }
      }
    } else {
      f = async () => {
        try {
          peerconn.createDataChannel("temp", {priority: "high"})
          let of = await peerconn.createOffer({iceRestart: true})
          await peerconn.setLocalDescription(of)
          this.send(MsgTyp.ClientOffer, peerconn.localDescription)
          this.status = STATUSES.offerSent
        } catch (e) {
          console.error(e)
          this.destroy()
        }
      }
    }
    f()
  }

  setConfig(config) {
    this.config = config;
    if (this.controlChannel) {
      this.controlChannel.send(JSON.stringify(config));
    }
  }

  #onCursor(cursor) {
    this.onCursorChanged && this.onCursorChanged(cursor);
  }

  startConnection() {
    let config = {}
    Object.assign(config, this.config)
    this.send(MsgTyp.InitStream, config);
    this.send(MsgTyp.RequestOffer, config);
    this.status = STATUSES.streamInit;
  }

  _msgHandler(data) {
    switch (data.type) {
      case ConnectRes:
        clearTimeout(this.timer)
        this.timer = null;
        if (data.payload.accepted) {
          this.status = STATUSES.accepted
          this.startConnection()
        } else {
          this.status = STATUSES.rejected
        }
        break;
      case MsgTyp.HostOffer:
        this.startPC(data.payload)
        break;
      case MsgTyp.HostCandidate:
        this.addIceCandidate(data.payload)
        break;
      case MsgTyp.HostAnswer:
        this.status = STATUSES.answerReceived
        if (this.peerConn) {
          this.peerConn.setRemoteDescription(data.payload).then(() => this.addIceCandidate())
        }
        break
      case MsgTyp.ErrorMsg:
        console.error(data.payload)
        break;
      default:
        console.error("Unexpected message", data)
    }
  }

  destroy() {
    if (this.peerConn) {
      this.peerConn.close();
    }

    this.stopMetricsMeasurement()
  }
}

export default WebrtcClient