import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Button, Tooltip, Typography} from '@mui/material';
import {PushPin, PushPinOutlined} from "@mui/icons-material";
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import ClockIcon from '@mui/icons-material/AccessTime';
import WaveIcon from '@mui/icons-material/Waves';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import TimerIcon from '@mui/icons-material/Timer';
import QueueIcon from '@mui/icons-material/Queue';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {togglePinMetrics} from '../../redux/MetricsSlice';

const getColor = (value, thresholds) => {
  if (!thresholds) return 'white';
  if (value <= thresholds?.white) return 'white';
  if (value <= thresholds?.yellow) return 'yellow';
  return 'red';
};

const getTooltipText = (value, thresholds, messages) => {
  if (value <= thresholds?.white || !thresholds) return messages.good;
  if (value <= thresholds?.yellow) return messages.warning;
  return messages?.high;
};

const MetricItem = ({icon: Icon, color, value, label, thresholds, tooltipMessages}) => {
  const toolTipMsg = getTooltipText(value, thresholds, tooltipMessages);
  return (<Tooltip title={toolTipMsg} placement="top" arrow>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 0.2, opacity: 0.7,}}>
        {Icon && <Icon sx={{color, fontSize: '1rem'}}/>}
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{fontSize: '0.7rem', lineHeight: 1.2, width: '100%', textAlign: 'left'}}
        >
          {value} {label}
        </Typography>
      </Box>
    </Tooltip>);
};

const ShowMetrics = () => {
  const {
    bitrate,
    latency,
    fps,
    jitter,
    skippedFrames,
    decodeDuration,
    framesInQueue,
    downloadSpeed,
    encodeDuration,
    accumulatedFrames
  } = useSelector((state) => state.metrics);
  const dispatch = useDispatch();
  const pinMetrics = useSelector(state => state.metrics.pinMetrics);

  const metrics = [{
    key: 'bitrate',
    value: bitrate,
    label: 'mbps',
    icon: NetworkCheckIcon,
    tooltipMessages: {good: 'Bitrate', warning: 'Bitrate is moderate', high: 'Bitrate is too high'}
  }, {
    key: 'latency',
    value: Math.round(latency),
    label: 'ms',
    icon: ClockIcon,
    thresholds: {white: 40, yellow: 60},
    tooltipMessages: {good: 'Latency', warning: 'Latency is moderate', high: 'Latency is high'}
  }, {
    key: 'fps',
    value: fps,
    label: 'fps',
    thresholds: {white: 5, yellow: 10},
    tooltipMessages: {good: 'Frames Per Second', warning: 'FPS is acceptable', high: 'FPS is too high'}
  }, {
    key: 'jitter',
    value: Math.round(jitter),
    label: 'ms',
    icon: WaveIcon,
    thresholds: {white: 20, yellow: 40},
    tooltipMessages: {
      good: 'Jitter',
      warning: 'Jitter: Switch to 5Ghz WiFi, Ensure you have Good signal strength',
      high: 'Jitter: Switch to 5Ghz WiFi, Ensure you have Good signal strength'
    }
  }, {
    key: 'skippedFrames',
    value: skippedFrames,
    label: '',
    icon: CancelPresentationIcon,
    thresholds: {white: 1, yellow: 3},
    tooltipMessages: {
      good: 'Skipped frames',
      warning: 'Skipped frames: Switch to 5Ghz WiFi, Ensure you have Good signal strength',
      high: 'Skipped frames: Switch to 5Ghz WiFi, Ensure you have Good signal strength'
    }
  }, {
    key: 'decodeDuration',
    value: Math.round(decodeDuration),
    label: 'ms',
    icon: TimerIcon,
    thresholds: {white: 5, yellow: 10},
    tooltipMessages: {
      good: 'Decode duration', warning: 'Decode duration is moderate', high: 'Decode duration is too slow'
    }
  }, {
    key: 'framesInQueue',
    value: framesInQueue,
    label: '',
    icon: QueueIcon,
    thresholds: {white: 2, yellow: 5},
    tooltipMessages: {
      good: 'Frames in Queue',
      warning: 'Frames in Queue: Try reducing bitrate and resolution',
      high: 'Frames in Queue: Try reducing bitrate and resolution'
    }
  }, {
    key: 'downloadSpeed', value: downloadSpeed, label: 'mbps', icon: FileDownloadIcon, tooltipMessages: {
      good: 'Download Speed',
      warning: 'Download Speed: Try reducing bitrate and resolution',
      high: 'Download Speed: Try reducing bitrate and resolution'
    }
  }, {
    key: 'encodeDuration',
    value: encodeDuration,
    label: 'ms',
    icon: TimerIcon,
    tooltipMessages: {good: 'Encode duration'}
  }, {
    key: 'accumulatedFrames',
    value: accumulatedFrames,
    label: '',
    icon: QueueIcon,
    tooltipMessages: {good: 'Accumulated Frames'}
  },];


  return (<>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          padding: {xs: '0.1rem 0.1rem', sm: '0.2rem 1rem'},
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          zIndex: (theme) => theme.zIndex.mobileStepper - 1,
          boxShadow: 3,
          textAlign: 'center',
          display: 'flex',
          gap: {xs: 2, sm: 3},
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Button
          onClick={() => dispatch(togglePinMetrics())}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: 0.7,
            padding: 0,
            minWidth: 0,
            background: 'transparent',
            border: 'none',
          }}
          disableRipple
          variant="text"
        >
          {pinMetrics ? (<PushPin sx={{fontSize: '1rem'}}/>) : (<PushPinOutlined sx={{fontSize: '1rem'}}/>)}
        </Button>

        {metrics.map(({icon, value, label, thresholds, tooltipMessages}, index) => (<MetricItem
            key={index}
            icon={icon}
            color={getColor(value, thresholds)}
            value={value}
            label={label}
            thresholds={thresholds}
            tooltipMessages={tooltipMessages}
          />))}
      </Box>
    </>);
};

export default ShowMetrics;