import { createSlice } from '@reduxjs/toolkit';

const MetricsSlice = createSlice({
  name: 'metrics',
  initialState: {
    bitrate: null,
    latency: null,
    fps: null,
    jitter: null,
    skippedFrames: null,
    decodeDuration: null,
    framesInQueue: null,
    downloadSpeed: null,
    encodeDuration:null,
    accumulatedFrames:null,
    pinMetrics: false,
  },
  reducers: {
    updateMetrics: (state, action) => {
      const { type, value } = action.payload;
      state[type] = value;
    },
    togglePinMetrics: (state) => {
      state.pinMetrics = !state.pinMetrics;
    },
  },
});

export const { updateMetrics, togglePinMetrics } = MetricsSlice.actions;
export default MetricsSlice.reducer;
