import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import auth from "../api/auth";
import logout from "../api/logout";
import {googleLogout} from "@react-oauth/google";


const checkAuth = createAsyncThunk(
  "auth/checkAuth",
  async (payload, thunkAPI) => {
    return auth();
  }
)


const logoutThunk = createAsyncThunk(
  "auth/login",
  async () => {
    googleLogout();
    return await logout();
  }
)


const AuthSlice = createSlice({
  name: 'auth',
  initialState: {
    loadingState: 'idle',
    authenticated: false
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkAuth.pending, (state, action) => {
      state.loadingState = 'loading'
    })
    builder.addCase(checkAuth.fulfilled, (state, action) => {
      state.loadingState = 'success'
      state.authenticated = action.payload.authenticated
      state.user = action.payload.user
    })
    builder.addCase(checkAuth.rejected, (state, action) => {
      state.loadingState = 'failed'
      state.authenticated = false;
    })

    builder.addCase(logoutThunk.fulfilled, (state, action) => {
      if (action.payload.success) {

        state.authenticated = false;
        state.user = undefined;
      }
    })
  }
})

export {checkAuth, logoutThunk};

export default AuthSlice.reducer;