import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { isBrowserSupported, getCodecSupportedInBrowser } from "./SettingsValidation";
import { setInitialConfig } from "../../redux/SettingsSlice";
import PopUpDialogBox from "../../pages/Home/PopUpDialogBox";
import {setBrowserValidated} from "../../redux/ClientSettingsSlice";

const ValidateBrowser = ({ children }) => {
    const [browserValidation, setBrowserValidation] = useState({ isValid: false, loading: true });
    const [codecValidation, setCodecValidation] = useState({ isValid: false, loading: true });
    const dispatch = useDispatch();
    const isBrowserValidated = useSelector(state => state.client_settings.isBrowserValidated);

    useEffect(() => {
        const initializeSettings = async () => {
            const browserIsValid = isBrowserSupported();
            setBrowserValidation({ isValid: browserIsValid, loading: false });
            if (browserIsValid && !isBrowserValidated) {
                const codecConfig = await getCodecSupportedInBrowser();
                setCodecValidation({
                    isValid: codecConfig.isSupported,
                    loading: false,
                });
                if (codecConfig.isSupported) {
                    dispatch(
                        setInitialConfig({
                            codecType: codecConfig.codecType,
                            codecString: codecConfig.codecString,
                            hardware_acceleration : codecConfig.hardware_acceleration
                        })
                    );
                }
            } else {
                setCodecValidation({ isValid: false, loading: false });
            }
            dispatch(setBrowserValidated(true))

        };

        initializeSettings();
    }, [dispatch, codecValidation.isValid]);

    return (
        <>
        {
        browserValidation.isValid ? 
        children : 
        <PopUpDialogBox
            browserValidation={browserValidation}
            codecValidation={codecValidation}
            message={
            !browserValidation.isValid
                ? "Your browser does not support the required features."
                : codecValidation.isValid
                ? "Browser and Codec are both supported."
                : "Browser Supported but Codec not supported."
            }
        />
        }
        </>
        
    );
};

export default ValidateBrowser;
