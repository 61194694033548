import React from 'react';
import {Box, Container, Grid, IconButton, Link, Typography} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import logo from "../../images/GamearenaLogo.svg"
import discord_logo from "../../images/discord.svg"

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: 'transparent',
                padding: 2,
                width: '100%',
                mt: 'auto',
                textAlign: 'center',
                color: 'white', 
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={0} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={6} md={3} textAlign="center">
                        <Typography variant="h6">
                            <img
                                src={logo}
                                alt="Logo"
                                style={{width: 45, height: "auto", mb: 2}}
                            />
                        </Typography>
                        <Box>
                            <IconButton
                                href="https://www.facebook.com/profile.php?id=61569650950446"
                                target="_blank"
                                aria-label="Facebook"
                            >
                                <FacebookIcon/>
                            </IconButton>
                            <IconButton
                                href="https://x.com/GamearenaStream"
                                target="_blank"
                                aria-label="Twitter"
                            >
                                <TwitterIcon/>
                            </IconButton>
                            <IconButton
                                href="https://www.instagram.com/gamearena_streaming/"
                                target="_blank"
                                aria-label="Youtube"
                            >
                                <YouTubeIcon/>
                            </IconButton>
                            <IconButton
                                href="https://discord.com/channels/1307691877537153084/1307691878610767914"
                                target="_blank"
                                aria-label="Discord"
                            >
                                <img src={discord_logo} alt="Discord" style={{ width: 20 }} />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} textAlign="center">
                        <Typography variant="h6" sx={{mb: 2}}>
                            Pages
                        </Typography>
                        <Box>
                            <Link href="/" underline="hover"  color = 'textSecondary'
                                  variant = 'body1'
                                  sx={{display: 'block', mb: 1  }}>
                                Home
                            </Link>
                            <Link href="https://gamearena.framer.website/about" underline="hover" color = 'textSecondary'  variant = 'body1'
                                  sx={{display: 'block', mb: 1 }}>
                                About
                            </Link>
                            <Link href="https://gamearena.framer.website/#features" underline="hover"  color = 'textSecondary'  variant = 'body1'
                                  sx={{display: 'block', mb: 1}}>
                                Features
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} textAlign="center">
                        <Typography variant="h6" sx={{mb: 2 }}>
                            Resources
                        </Typography>
                        <Box>
                            <Link href="https://gamearena.framer.website/#faqs" underline="hover" color='textSecondary'  variant = 'body1'
                                  sx={{display: 'block', mb: 1}}>
                                FAQ
                            </Link>
                            <Link href="https://gamearena.framer.website/integration" underline="hover" color='textSecondary'  variant = 'body1'
                                  sx={{display: 'block', mb: 1}}>
                                Integrations
                            </Link>
                            <Link href="/login" underline="hover" color='textSecondary'  variant = 'body1' sx={{display: 'block', mb: 1,}}>
                                Login
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} textAlign="center">
                        <Typography variant="h6" sx={{mb: 2 }}>
                            Company
                        </Typography>
                        <Box>
                            <Link href="/terms-of-service" underline="hover" color="textSecondary"  variant = 'body1'
                                  sx={{display: 'block', mb: 1}}>
                                Terms of Service
                            </Link>
                            <Link href="/privacy-policy" underline="hover" color="textSecondary"  variant = 'body1'
                                  sx={{display: 'block', mb: 1}}>
                                Privacy Policy
                            </Link>
                            <Link href="https://gamearena.framer.website/contact-us" underline="hover" color="textSecondary"  variant = 'body1'
                                  sx={{display: 'block', mb: 1}}>
                                Contact Us
                            </Link>
                            <Link href="/signup" underline="hover" color='textSecondary'  variant = 'body1' sx={{display: 'block', mb: 1}}>
                                Signup
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
                <Typography
                    variant="body1"
                    align="center"
                    color ='textSecondary'
                    sx={{mt: 3}}
                >
                    © {new Date().getFullYear()} RhinoStream Technologies. All Rights Reserved.
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
