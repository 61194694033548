import Chup from "./Chup";

class VideoChunkProcessor {
  onVideoFrame;
  onBadFrame;
  onMetadata;

  bufferView;

  badframe = true;
  frameIdx;

  chup;
  scream

  constructor(scream, webrtcClient) {
    this.badframe = true;
    this.scream = scream
    this.frameIdx = -1;

    this.webrtcClient = webrtcClient;
    this.chup = new Chup(this.handleChupData.bind(this), this.handleChupDataLoss.bind(this), webrtcClient)
  }

  #sendFrame() {
    this.onVideoFrame && this.webrtcClient && this.webrtcClient.frameCount++;
    this.onVideoFrame && this.onVideoFrame(this.bufferView.slice(0, this.bufferView.length))
  }

  #handleBadFrame() {
    console.log("badframe", this.frameIdx);
    this.onBadFrame && this.onBadFrame()
  }

  handleChupDataLoss() {
    this.badframe = true
    this.#handleBadFrame()
  }

  #sendMetadata(metadata) {
    this.onMetadata && this.onMetadata(metadata)
  }

  handleChupData(data, dataIdx) {
    this.frameIdx = dataIdx
    // parse the scream encoded data
    try {
      let frame = this.scream.parse(data)
      this.#sendMetadata(Object.assign({}, frame, {chunk: undefined}))
      this.bufferView = new Uint8Array(frame.chunk)
      if (this.badframe && (this.bufferView[4] === 103 || this.bufferView[4] === 0x40)) {
        this.badframe = false;
      }

    } catch (e) {
      this.badframe = true;
      console.error("failed to parse scream. ", e)
    }

    if (this.badframe) {
      // contains bad frame so, PLI
      this.#handleBadFrame()
    } else {
      this.#sendFrame()
    }

  }


  push(packet) {
    try {
      const parsed_packet = this.scream.parse(packet)
      this.chup.push(parsed_packet)
    } catch (e) {
      console.error(e)
    }
  }
}

export default VideoChunkProcessor
