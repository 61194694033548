import React from 'react';
import { Backdrop, Paper, Typography, Box, CircularProgress, Button } from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';

const PopUpDialogBox = ({ browserValidation, codecValidation, message, action }) => {
    const RenderValidation = ({title, validation}) => (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 2,
                borderBottom: '1px solid #333',
                ':last-child': { borderBottom: 'none' },
            }}
        >
            <Typography variant="body1" color="#ffffff">
                {title}
            </Typography>
            {validation?.loading ? (
                <CircularProgress size={20} color="inherit" />
            ) : validation?.isValid ? (
                <CheckCircle sx={{ color: '#4caf50' }} />
            ) : (
                <Cancel sx={{ color: '#f44336' }} />
            )}
        </Box>
    );

    return (
        <Backdrop
            open={true}
            sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: '#121212',
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: 4,
                    textAlign: 'center',
                    maxWidth: 600,
                    borderRadius: '16px',
                    backgroundColor: '#1e1e1e',
                    color: '#ffffff',
                }}
            >
                <Typography variant="h5" color={!browserValidation?.isValid ? "#ff5252" : "green"} gutterBottom>
                    Validation Results
                </Typography>
                <Typography variant="body2" color="#cfcfcf" mb={3}>
                    {browserValidation?.loading || codecValidation?.loading ? 
                    "Checking your browser and codec compatibility. Please wait..." :
                     message}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        backgroundColor: '#2a2a2a',
                        borderRadius: '8px',
                        overflow: 'hidden',
                    }}
                >
                    
                    {browserValidation && <RenderValidation title="Browser Validation" validation={browserValidation} /> }
                    {codecValidation && <RenderValidation title="Codec Validation" validation={codecValidation} /> }

                </Box>

                {action && (
                    <Button
                    onClick={action}
                    variant="contained"
                    sx={{
                        marginTop: 3,
                        backgroundColor: '#f44336',
                        ':hover': { backgroundColor: '#d32f2f' },
                    }}
                >
                    Close
                </Button>
                )}

            </Paper>
        </Backdrop>
    );
};

export default PopUpDialogBox;
