import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    useTheme, 
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {checkAuth, logoutThunk} from "../../redux/AuthSlice";
import {Link, Route, Routes} from "react-router-dom";
import logo from "../../images/GamearenaLogo.svg"
import Devices from "../Devices/Devices";
import Profile from "../../components/Profile/Profile";
import {LogoutTwoTone, PersonTwoTone, Settings} from "@mui/icons-material";
import SettingsMenu from "../../components/StreamClient/Settings/SettingsMenu";
import Footer from "../Footer/Footer";
import ValidateBrowser from "../../components/Validation/ValidateBrowser";
import PrivacyPolicy from "../legal/PrivacyPolicy";
import TermsAndConditions from "../legal/TermsAndConditions";

const UserBadge = ({user, logout}) => {
    const theme = useTheme();
    const [menuBtn, setMenuBtn] = useState(null);
    const open = Boolean(menuBtn)
    const [profileOpen, setProfileOpen] = useState(false);
    const handleClick = (e) => {
        setMenuBtn(e.currentTarget)
    }

    const handleClose = () => {
        setMenuBtn(null)
    }
    return (<>
        <IconButton ref={menuBtn} onClick={handleClick}>
            {user.picture ? <Avatar sx={{background: theme.palette.secondary.main}} alt={user.first_name}
                                    src={user.picture}></Avatar> :
                <Avatar
                    sx={{background: theme.palette.secondary.main}}>{user.first_name[0]}</Avatar>
            }
        </IconButton>
        <Menu onClose={handleClose} anchorEl={menuBtn} open={open}>
            <MenuItem dense onClick={() => setProfileOpen(true)} sx={{minWidth: '150px'}}>
                <ListItemIcon>
                    <PersonTwoTone fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary={"Profile"}/>
            </MenuItem>
            <MenuItem dense onClick={logout}>
                <ListItemIcon>
                    <LogoutTwoTone fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary={"logout"}/>
            </MenuItem>
        </Menu>
        <Profile open={profileOpen} onClose={() => setProfileOpen(false)}/>
    </>)
}

const Home = () => {
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(logoutThunk())
    }
    useEffect(() => {
        dispatch(checkAuth())
    }, []);

    return (<>
        <Container sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh"
        }}>
                <AppBar position={'absolute'} variant={"elevation"} elevation={0} color={"transparent"}>
                <Toolbar variant={"regular"}>
                    <Link to="/">
                        <Avatar style={{height: 45, width: 45}} src={logo} alt={"logo"}/>
                    </Link>
                    <Box flex={1}/>
                    <IconButton component={Link} to={"/settings"}><Settings/></IconButton>
                    {user ?
                        <UserBadge user={user} logout={logout}/>
                        : <Button component={Link} to={"/login"}>Login</Button>}
                </Toolbar>
            </AppBar>
            <ValidateBrowser>
                <Routes>
                    <Route path={"/"} element={<Devices/>}></Route>
                    <Route path={"/settings"} element={<SettingsMenu/>}></Route>
                    <Route path={"/privacy-policy"} element={<PrivacyPolicy/>}/>
                    <Route path={"/terms-of-service"} element={<TermsAndConditions/>}/>
                </Routes>
                </ValidateBrowser>
            <Footer/>
        </Container>
    </>)
}
export default Home
