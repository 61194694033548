function isBrowserSupported() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Opera") !== -1 || userAgent.indexOf('OPR') !== -1) {
        return false;
    } else if (userAgent.indexOf("Edg") !== -1) {
        return true;
    } else if (userAgent.indexOf("Chrome") !== -1) {
        return true;
    } else if (userAgent.indexOf("Safari") !== -1) {
        return true;
    } else if (userAgent.indexOf("Firefox") !== -1) {
        return false;
    } else if ((userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
        return true;
    } else {
        return true;
    }
};
const codecPriorityArray =
    [{codec: "HEVC", codecString: "hev1.1.6.L93.B0", hardware_acceleration: "prefer-hardware"},
        {codec: "H264", codecString: "avc1.64003E", hardware_acceleration: "prefer-hardware"}, {
        codec: "H264",
        codecString: "avc1.42001E",
        hardware_acceleration: "prefer-hardware"
    }, {codec: "H264", codecString: "avc1.64003E", hardware_acceleration: "prefer-software"}, {
        codec: "H264",
        codecString: "avc1.42001E",
        hardware_acceleration: "prefer-software"
    }]
;

// Function to check if a specific codec is supported
async function isCodecSupported(codecConfig) {
    try {
        const config = {
            codec: codecConfig.codecString,
            optimizeForLatency: true,
            hardwareAcceleration: codecConfig.hardware_acceleration,
        };
        const result = await window.VideoDecoder.isConfigSupported(config);
        return result.supported;
    } catch (error) {
        console.error(`Error checking codec support for ${codecConfig}:`, error);
        return false;
    }
}

async function getCodecSupportedInBrowser() {
    if (typeof VideoDecoder === "undefined") {
        console.error("WebCodecs API (VideoDecoder) is not supported in this browser.");
        return null;
    }
    const codecConfig = {
        codecType: null,
        codecString: "",
        isSupported: false,
        hardware_acceleration: null
    }

        for (const codecPreset of codecPriorityArray) {
            const supported = await isCodecSupported(codecPreset);
            if (supported) {
                codecConfig['codecType'] = codecPreset["codec"];
                codecConfig['codecString'] = codecPreset["codecString"];
                codecConfig['isSupported'] = true;
                codecConfig['hardware_acceleration'] = codecPreset["hardware_acceleration"];
                return codecConfig;
            }
    }
    console.warn("No supported codec found.");
    return codecConfig;
}


export {isBrowserSupported, getCodecSupportedInBrowser, isCodecSupported};