import React, {useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Link, Typography,} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SupportIcon from "@mui/icons-material/Support";
import SecurityIcon from "@mui/icons-material/Security";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Profile from "../../Profile/Profile";
import {useDispatch} from "react-redux";
import {setIsDeveloper} from "../../../redux/ClientSettingsSlice";
import {setAlert} from "../../../redux/AlertSlice";

const FaqAccordion = ({title, description, idx, expanded, handleChange}) => {
    return (
        <Accordion
            expanded={expanded === idx}
            onChange={() => handleChange(idx)}
            variant={"elevation"}
            elevation={1}
            type={"glass"}
            sx={{my: 1, "&::before": {display: "none"}}}
            disableGutters
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls={`${title}-content`}
                id={`${title}-header`}
            >
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{description}</AccordionDetails>
        </Accordion>
    );
};

const faqContent = [
    {
        title: "What is the minimum internet speed required?",
        description:
            "Minimum 30 Mbps of stable internet connection is required for smooth experience.",
    },
    {
        title: "What Operating Systems are supported for hosting?",
        description:
            "Game Arena supports Windows operating systems for hosting PC games and sharing it on the web browser.",
    },
    {
        title: "From which devices can I play my games?",
        description:
            "You can run your games from any device (tablet, laptop, and mobile) using the Game Arena browser to access your PC.",
    },
    {
        title: "Is my personal information secure?",
        description:
            "Yes, we take data privacy and security seriously. Your personal information will be encrypted and stored securely.",
    },
    {
        title: "What does the Free plan include?",
        description:
            "The Free Plan is a generous offering designed for flexible and extensive experimentation, providing a broad range of features across various aspects.",
    },
    {
        title: "How do I install Game Arena?",
        description: "Install Game Arena and get started on your gaming journey.",
    },
    {
        title: "How to report an issue?",
        description: (
            <>
                If you run into an issue or bug, you can report it so we can solve it.
                Email us at{" "}
                <Link
                    underline="hover"
                    color="textSecondary"
                    variant="body1"
                    href="mailto:support@gamearena.tech"
                    sx={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                    }}
                >
                    support@gamearena.tech
                </Link>
                .
            </>
        ),
    },
];

const SettingsAbout = () => {
    const dispatch = useDispatch();
    const [clickCount, setClickCount] = useState(0);
    const [openProfile, setOpenProfile] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleOpenProfile = () => {
        setOpenProfile(true);
    };

    const handleCloseProfile = () => {
        setOpenProfile(false);
    };

    const handleClick = () => {
        const newClickCount = clickCount + 1;
        setClickCount(newClickCount);

        if (newClickCount >= 10) {
            dispatch(setIsDeveloper(true));
            dispatch(setAlert({msg: "Enabled developer mode", severity: "success"}))
        }
    };

    const handleAccordionChange = (idx) => {
        setExpanded(expanded === idx ? false : idx); // Toggle accordion state
    };

    return (
        <>
            <Typography
                variant="h7"
                color="textPrimary"
                sx={{display: "flex", alignItems: "center", mb: 2}}
            >
                <SecurityIcon sx={{mr: 1}}/>
                <strong>Version:</strong> <span onClick={handleClick}> 1.0.0 </span>
            </Typography>
            <Card
                elevation={3}
                type={"glass"} sx={{my: 1}}
            >
                <CardContent>
                    <Typography
                        variant="body1"
                        sx={{display: "flex", alignItems: "center"}}
                    >
                        <SupportIcon sx={{my: 1, mr: 1}}/>
                        <strong>Support</strong>
                    </Typography>
                    <Typography variant="body2">
                        Email:{" "}
                        <Link
                            underline="hover"
                            color="textSecondary"
                            variant="body1"
                            href="mailto:support@gamearena.tech"
                            sx={{
                                textDecoration: "underline",
                                fontWeight: "bold",
                            }}
                        >
                            support@gamearena.tech
                        </Link>
                    </Typography>
                </CardContent>
            </Card>
            <Card variant={"elevation"} type={"glass"} sx={{my: 1}}>
                <CardContent>
                    <Typography
                        variant="body1"
                        sx={{display: "flex", alignItems: "center"}}
                    >
                        <AccountCircleIcon sx={{my: 1, mr: 1}}/>
                        <strong>Accounts</strong>
                    </Typography>
                    <Typography variant="body2" sx={{mb: 1}}>
          <span
              onClick={handleOpenProfile}
              style={{textDecoration: "none", color: "inherit", cursor: "pointer"}}
          >
            Change Password
          </span>
                    </Typography>

                    <Typography variant="body2" sx={{mb: 1}}>
                        <a href="/delete-account" style={{textDecoration: "none", color: "inherit"}}>
                            Delete Account
                        </a>
                    </Typography>
                </CardContent>
            </Card>
            <Card variant={"elevation"} type={"glass"}>
                <CardContent>
                    <Typography
                        variant="body1"
                        sx={{display: "flex", alignItems: "center"}}
                    >
                        <SecurityIcon sx={{mr: 1, my: 1}}/>
                        <strong>Legal</strong>
                    </Typography>
                    <Typography variant="body2">
                        <a href="/terms-of-service" style={{textDecoration: "none", color: "inherit"}}>
                            Terms
                        </a>
                    </Typography>
                    <Typography variant="body2">
                        <a href="/privacy-policy" style={{textDecoration: "none", color: "inherit"}}>
                            Privacy Policy
                        </a>
                    </Typography>
                </CardContent>
            </Card>

            <Typography
                variant="body1"
                color="textPrimary"
                sx={{display: "flex", alignItems: "center", mt: 3, mb: 1}}
            >
                <QuestionAnswerIcon sx={{mr: 1}}/>
                <strong>FAQ</strong>
            </Typography>

            {faqContent.map((faq, idx) => (
                <FaqAccordion
                    key={idx}
                    title={faq.title}
                    description={faq.description}
                    idx={idx}
                    expanded={expanded}
                    handleChange={handleAccordionChange}
                />
            ))}
            <Profile open={openProfile} onClose={handleCloseProfile}/>
        </>
    );
};

export default SettingsAbout;
