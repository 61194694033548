import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';

const NetworkStatus = () => {
  const { bitrate, latency, fps, jitter, skippedFrames, decodeDuration, framesInQueue, downloadSpeed } = useSelector(
    (state) => state.metrics
  );

  const metrics = [
    { key: 'bitrate', value: bitrate, thresholds: { white: 5, yellow: 10 } },
    { key: 'latency', value: latency, thresholds: { white: 40, yellow: 60 } },
    { key: 'fps', value: fps, thresholds: { white: 5, yellow: 10 } },
    { key: 'jitter', value: jitter, thresholds: { white: 20, yellow: 40 } },
    { key: 'skippedFrames', value: skippedFrames, thresholds: { white: 1, yellow: 3 } },
    { key: 'decodeDuration', value: decodeDuration, thresholds: { white: 5, yellow: 10 } },
    { key: 'framesInQueue', value: framesInQueue, thresholds: { white: 2, yellow: 5 } },
    { key: 'downloadSpeed', value: downloadSpeed, thresholds: { white: 2, yellow: 5 } },
  ];

  const thresholdStatus = metrics.map(({ value, thresholds }) => {
    if (value > thresholds.yellow) return 'red';
    if (value > thresholds.white) return 'yellow';
    return 'white';
  });

  const yellowCount = thresholdStatus.filter((status) => status === 'yellow').length;
  const redCount = thresholdStatus.filter((status) => status === 'red').length;

  const networkIconColor = redCount > 0 ? 'red' : yellowCount > 0 ? 'yellow' : null;

  if (!networkIconColor) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 1,
        left: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        padding: '0.5rem',
        pointerEvents: 'none'
      }}
    >
      <NetworkCheckIcon sx={{ color: networkIconColor, fontSize: '1rem' }} />
    </Box>
  );
};

export default NetworkStatus;
