import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Slider,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import {
    Cancel, CodeTwoTone,
    HdTwoTone,
    ImageAspectRatioTwoTone,
    NetworkCheck,
    Save,
    SpeedTwoTone,
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {setStreamConfig} from "../../redux/SettingsSlice";

const SettingsDialog = styled(Dialog)(() => ({}));

const OverlaySetting = ({open: propOpen, onClose}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(propOpen);
    const [settingsState, setSettingState] = useState({});
    const [menuOpen, setMenuOpen] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const streamSettings = useSelector((state) => state.settings);
    const isDeveloper = useSelector((state) => state.client_settings.isDeveloper)
    console.log("developer ", isDeveloper)
    useEffect(() => {
        setOpen(propOpen);
    }, [propOpen]);

    useEffect(() => {
        setSettingState(streamSettings);
    }, [streamSettings]);

    const openMenu = (menuId) => (e) => {
        setAnchorEl(e.currentTarget);
        setMenuOpen(menuId);
    };

    const setValue = (name, value) => {
        setSettingState({...settingsState, [name]: value});
        closeMenu();
    };

    const closeMenu = () => {
        setMenuOpen("");
    };

    return (
        <>
            <SettingsDialog
                fullWidth maxWidth={"xs"} open={open} onClose={onClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        dispatch(setStreamConfig({...settingsState}));
                        onClose()
                    },
                }}>
                <DialogTitle>
                    Stream Settings
                </DialogTitle>
                <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                    <ListSubheader color={"primary"}>Video settings</ListSubheader>
                    <ListItemButton dense onClick={openMenu("resolution")}>
                        <ListItemIcon>
                            <HdTwoTone/>
                        </ListItemIcon>
                        <ListItemText primary={"Resolution"}/>
                        <ListItemSecondaryAction>
                            <Typography variant={"body2"}
                                        color={"textSecondary"}> {settingsState.resolution?.width} x {settingsState.resolution?.height}</Typography>
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    <ListItemButton dense onClick={openMenu("framerate")}>
                        <ListItemIcon>
                            <SpeedTwoTone/>
                        </ListItemIcon>
                        <ListItemText primary={"Framerate"}/>
                        <ListItemSecondaryAction>
                            <Typography variant={"body2"}
                                        color={"textSecondary"}> {settingsState.framerate} fps</Typography>
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    <ListItemButton onClick={() => setMenuOpen("bitrate")} dense>
                        <ListItemIcon>
                            <NetworkCheck/>
                        </ListItemIcon>
                        <ListItemText primary={"Bitrate"}/>
                        <ListItemSecondaryAction>
                            <Typography variant={"body2"}
                                        color={"textSecondary"}> {settingsState.bitrate / (1024 * 1024)} mbps</Typography>
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    <Collapse in={menuOpen === "bitrate"}>
                        <ListItem dense>
                            <ListItemText inset>
                                <Slider
                                    onChange={(e, v) => setSettingState({...settingsState, bitrate: v * 1024 * 1024})}
                                    valueLabelDisplay="auto" min={0.5} step={0.5}
                                    max={25} value={settingsState.bitrate / (1024 * 1024)}/>
                            </ListItemText>
                        </ListItem>
                    </Collapse>
                    <ListItem dense>
                        <ListItemIcon>
                            <ImageAspectRatioTwoTone/>
                        </ListItemIcon>
                        <ListItemText primary={"Change Resolution"}/>
                        <Checkbox size={"small"}
                                  onClick={() => setValue('change_resolution', !settingsState.change_resolution)}
                                  checked={settingsState.change_resolution}/>
                    </ListItem>
                    {isDeveloper && (<>
                   <ListItem dense>
                        <ListItemIcon>
                            <CodeTwoTone/>
                        </ListItemIcon>
                        <ListItemText primary={"Source options"}/>
                        <TextField size={"small"} defaultValue={settingsState?.extra_opt?.source}
                                   placeholder={"extra options for video source"}
                                   name={"source"}
                                   onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"source": event.target.value}))}/>
                    </ListItem>
                    <ListItem dense>
                        <ListItemIcon>
                            <CodeTwoTone/>
                        </ListItemIcon>
                        <ListItemText primary={"Filter options"}/>
                        <TextField size={"small"} defaultValue={settingsState?.extra_opt?.filter}
                                   placeholder={"extra options for color filter"}
                                   name={"Filter"}
                                   onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"filter": event.target.value}))}/>
                    </ListItem>
                    <ListItem dense>
                        <ListItemIcon>
                            <CodeTwoTone/>
                        </ListItemIcon>
                        <ListItemText primary={"Processor options"}/>
                        <TextField size={"small"} defaultValue={settingsState?.extra_opt?.processor}
                                   placeholder={"extra options for video encoder"}
                                   name={"Processor"}
                                   onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"processor": event.target.value}))}/>
                    </ListItem></>)
            }

                </List>
                <Menu open={menuOpen === "resolution"} anchorEl={anchorEl} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }} onClose={closeMenu}>
                    <MenuItem onClick={() => setValue("resolution", {width: 1280, height: 720})} value="1280x720">
                        1280 x 720
                    </MenuItem>
                    <MenuItem onClick={() => setValue("resolution", {width: 1920, height: 1080})} value="1920x1080">
                        1920 x 1080
                    </MenuItem>
                    <MenuItem onClick={() => setValue("resolution", {width: 2560, height: 1440})} value="2560x1440">
                        2560 x 1440
                    </MenuItem>
                    <MenuItem onClick={() => setValue("resolution", {width: 3840, height: 2160})} value="3840x2160">
                        3840 x 2160
                    </MenuItem>
                    <MenuItem onClick={openMenu("resolution_custom")}>Custom...</MenuItem>
                </Menu>
                <Menu open={menuOpen === "framerate"} anchorEl={anchorEl} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }} onClose={closeMenu}>
                    <MenuItem onClick={() => setValue("framerate", 30)}>
                        30 fps
                    </MenuItem>
                    <MenuItem onClick={() => setValue("framerate", 60)}>
                        60 fps
                    </MenuItem>
                    <MenuItem onClick={() => setValue("framerate", 120)}>
                        120 fps
                    </MenuItem>

                </Menu>

                <DialogActions>
                    <Button color={"error"} startIcon={<Cancel/>} type={"submit"}>cancel</Button>
                    <Button color={"primary"} startIcon={<Save/>} variant={"outlined"} type={"submit"}>Save</Button>
                </DialogActions>
            </SettingsDialog>
            <Dialog open={menuOpen === "resolution_custom"}
                    onClose={closeMenu}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (e) => {
                            e.preventDefault();
                            let formdata = new FormData(e.currentTarget);
                            let dataJson = Object.fromEntries(formdata.entries());
                            setValue("resolution", {width: Number(dataJson.width), height: Number(dataJson.height)})
                        }
                    }}
            >
                <DialogTitle>Custom Resolution</DialogTitle>
                <DialogContent>
                    <TextField autoFocus sx={{m: 1}} defaultValue={settingsState?.resolution?.width} label={"Width"}
                               name={"width"}/>
                    <TextField sx={{m: 1}} label={"Height"} name={"height"}
                               defaultValue={settingsState?.resolution?.height}/>
                </DialogContent>
                <DialogActions>
                    <Button type={"submit"}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OverlaySetting;
