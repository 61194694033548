class Chup {
  onNewData
  onDataLoss

  buffer

  packets = [];
  dataIdx = -2;
  numPackets;

  firstPacketTime = null
  lastPacketTime = null
  totalFrameSize = 0

  // Constructor takes two callbacks: one for new data and one for packet loss notification.
  // These callbacks will be invoked when new data is available or when there's a data loss respectively.
  constructor(onNewData, onDataLoss, webrtcClient) {
    this.onNewData = onNewData;
    this.onDataLoss = onDataLoss

    this.buffer = new ArrayBuffer(0, {maxByteLength: 1024 * 1024 * 10});

    this.webrtcClient = webrtcClient;

  }

  calcDownloadSpeed() {
    if (this.firstPacketTime && this.lastPacketTime) {
      const timeDiff = (this.lastPacketTime - this.firstPacketTime) / 1000;
      if (timeDiff !== 0) {
        const downloadSpeed = this.totalFrameSize / timeDiff;
        const downloadSpeedMbps = (downloadSpeed * 8) / (1024 * 1024);

        this.webrtcClient.updateDownloadSpeed(downloadSpeedMbps);
      }
    }

    this.firstPacketTime = null;
    this.lastPacketTime = null;
    this.totalFrameSize = 0;
  }

  // collects all the relevant packets here. When we have full set, we combine them into single array buffer and
  // call callback.
  push(packet) {
    const packetSize = packet.data.byteLength;

    // this is not the data we are currently processing
    if (this.dataIdx !== packet.data_idx) {
      // 1. it could be the next packet.
      if (packet.data_idx === (this.dataIdx + 1) % 65536) {
        if (this.packets.length !== 0) {
          this.#onDataLoss()
        }
        // update data idx and numPackets
        this.dataIdx = packet.data_idx
        this.numPackets = packet.num_packets
        this.buffer.resize(0);
        this.packets = [];

        this.firstPacketTime = Date.now();
        this.totalFrameSize = packetSize;

      } else if (packet.data_idx < this.dataIdx && (this.dataIdx - packet.data_idx < 2)) {
        // remnant from previous data, ignore it.
        return
      } else {
        // we are missing some packets. Call the onDataLoss callback.
        this.#onDataLoss()
        this.dataIdx = packet.data_idx
        this.numPackets = packet.num_packets
        this.packets = [];
        this.buffer.resize(0);

        this.firstPacketTime = Date.now();
        this.totalFrameSize = packetSize;
      }
    }
    this.packets.push(packet)
    if (this.packets.length === this.numPackets) {
      this.lastPacketTime = Date.now();

      // we have full packet! Convert all packets to a single array buffer and call callback.
      this.packets.sort((a, b) => a.packet_idx - b.packet_idx);
      let copied = 0;
      this.buffer.resize(0);
      const view = new Uint8Array(this.buffer);
      for (let i = 0; i < this.numPackets; i++) {
        const packet = this.packets[i];
        if (packet.packet_idx !== i) {
          // packets are out of order. Call the onDataLoss callback.
          console.log("wrong packet index! Expected: " + i + ", got: " + packet.packet_idx + " for packet idx: " + i);
          this.#onDataLoss();
          break;
        }
        if (packet.data_idx !== this.dataIdx) {
          this.#onDataLoss()
          console.log("wrong packets for the index! Expected: " + this.dataIdx + ", got: " + packet.data_idx + " for packet idx: " + i);
          break;
        }
        this.buffer.resize(copied + packet.data.length)
        view.set(packet.data, copied)
        copied += packet.data.length
      }

      this.calcDownloadSpeed();

      this.#onNewData(this.buffer, this.dataIdx);
      this.packets = [];
    }
  }


  #onNewData(data, dataIdx) {
    this.onNewData && this.onNewData(data, dataIdx)
  }

  #onDataLoss() {
    this.onDataLoss && this.onDataLoss()
  }


}

export default Chup