import {useEffect, useRef, useState} from "react";
import webcodec from "./webcodec";
import {styled} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setBoundingRect} from "../../../../redux/CanvasSlice";

const StyledCanvas = styled('canvas')((theme) => ({
  display: "block",
  position: "absolute",
  JustifySelf: "center",
  alignSelf: "center",
  maxWidth: '100%',
  maxHeight: '100%',
  height: 'auto',
  objectFit:'contain'
}))
const WebCodecPlayer = ({cli}) => {
  const volume = useSelector(state => state.audio.volume);
  const codecStr = useSelector(state => state.settings.codec_string);
  const hardwareAcceleration = useSelector(state => state.settings.hardware_acceleration);
  const paintImmediately = useSelector(state => state.client_settings.immediate_paint);
  const [codec, setCodec] = useState(null);
  const view = useRef(null)
  const audioEl = useRef(null)
  const dispatch = useDispatch();

  useEffect(() => {
    if (cli && (codecStr !== "") && (hardwareAcceleration !== "")) {
      const codec = new webcodec(cli, {
        webcodecString: codecStr,
        hardwareAcceleration: hardwareAcceleration,
        paintImmediately: paintImmediately
      }, dispatch);
      setCodec(codec)
      return () => {
        codec.destroy();
      }
    }
  }, [cli, codecStr, hardwareAcceleration, paintImmediately]);

  useEffect(() => {
    if (codec) {
      codec.onResChange = (res) => {
        if (cli && view.current) {
          view.current.width = res.width;
          view.current.height = res.height
          console.log("change res")
          windowResized()
        }
      }
    }
  }, [codec, cli, view]);

  useEffect(() => {
    if (audioEl.current) {
      audioEl.current.volume = (volume / 100.0)
    }
  }, [audioEl, volume]);
  useEffect(() => {
    if (view.current && codec) {
      codec.setCanvas(view.current)
      // codec.onDebugUpdate = (update)=>{
      //   setTimeStamp(update)
      // }
    }
  }, [view, codec]);
  const windowResized = () => {
    // store window size and start and end points of canvas rect here
    let rect = view.current?.getBoundingClientRect();
    dispatch(setBoundingRect(Object.assign({}, JSON.parse(JSON.stringify(rect)))))
  }

  useEffect(() => {
    if (view.current) {
      const canvas = view.current;
      windowResized()
      window.addEventListener("resize", windowResized);
      return () => window.removeEventListener("resize", windowResized)
    }
  }, [view]);

  const setAudio = () => {
    audioEl.current.srcObject = cli.audioChannel;
  }
  useEffect(() => {
    if (cli && audioEl.current) {
      cli.ontrack = setAudio;
      setAudio()
    }
  }, [audioEl, cli]);
  return <>
    <StyledCanvas ref={view}/>
    <audio autoPlay playsInline ref={audioEl}/>
  </>

}
export default WebCodecPlayer