import { updateMetrics } from "../../../../redux/MetricsSlice";

class webcodec {
  lastFrame = null;
  canvas = null;
  ctx = null;
  onResChange;
  onDebugUpdate;

  loopCancel = null;

  paintImmediately = false;

  resolution = {
    width: 0,
    height: 0
  }

  isConfigured = false;

  skippedFrames = 0;
  skippedFramesTimer = null;

  decodeDurationsPerSec = [];
  decodeDurationTimer = null

  framesInQueueTimer = null


  constructor(cli, opt, dispatch) {

    this.decoder = new window.VideoDecoder({
      output: this.#onOutput.bind(this),
      error: this.#onError.bind(this)
    })
    if (!opt?.webcodecString) {
      opt = {
        webcodecString: "avc1.64003E",
        hardwareAcceleration: "prefer-hardware",
      }
    }
    this.decoder.configure({
      codec: opt.webcodecString,
      // codec: 'avc1.42001E',
      optimizeForLatency: true,
      hardwareAcceleration: opt.hardwareAcceleration,
    })
    cli.onVideoFrameReceived = this.pushFrame.bind(this);
    this.paintImmediately = !!opt.paintImmediately;
    if (!opt.paintImmediately) {
      this.loopCancel = requestAnimationFrame(this.startAnimationLoop.bind(this));
    }

    this.dispatch = dispatch;
    this.startSkippedFramesMeasurement();
    this.decodeDurationMeasurement()
    this.framesInQueueMeasurement()
  }

  setPaintImmediately(paintImmediately) {
    this.paintImmediately = paintImmediately;
    if (this.loopCancel && this.paintImmediately) {
      cancelAnimationFrame(this.loopCancel);
      this.loopCancel = null;
    } else if (!this.paintImmediately && !this.loopCancel) {
      this.loopCancel = requestAnimationFrame(this.startAnimationLoop.bind(this));
    }
  }

  paint(timestamp) {
    if (this.lastFrame && this.ctx) {
      let res = {
        width: this.lastFrame.displayWidth,
        height: this.lastFrame.displayHeight,
      };
      if (res.width !== this.resolution.width || res.height !== this.resolution.height) {
        console.log("wrong resolution")
        this.onResChange && this.onResChange(res)
        this.resolution = res
      }

      this.ctx.drawImage(this.lastFrame, 0, 0)
    }
    if (this.lastFrame) {
      this.lastFrame.close()
      this.lastFrame = null;
    }
  }

  #onOutput(frame) {
    const endTime = performance.now()
    const startTime = frame.timestamp
    if(startTime){
      const currDecodeDuration = endTime - startTime
      this.decodeDurationsPerSec.push(currDecodeDuration) // decode duration for each frame
    }

    if (this.paintImmediately) {
      this.lastFrame = frame;
      this.paint(0)
    }
    if (this.lastFrame) {
      this.lastFrame.close()
      this.skippedFrames++;
    }
    this.lastFrame = frame;
  }

  #onError(e) {
    console.error(e)
  }

  startAnimationLoop() {
    this.paint(0)
    requestAnimationFrame(this.startAnimationLoop.bind(this))
  }

  pushFrame(frame) {
    if (this.decoder) {
      const key = isRefFrame(frame)

      if (key && !this.isConfigured) {
        this.isConfigured = true;
      }

      this.decoder.decode(new window.EncodedVideoChunk({
        data: frame,
        type: key ? "key" : "delta",
        timestamp: performance.now(), // contains decode start time for each frame
      }));


    }
  }

  setCanvas(canvas) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d" , {
      desynchronized: true,
    });

  }

  startSkippedFramesMeasurement(){
    this.skippedFramesTimer = setInterval(() => {
      this.dispatch(updateMetrics({type: "skippedFrames", value: this.skippedFrames}))
      this.skippedFrames =0
    }, 1000)
  }

  decodeDurationMeasurement(){
    this.decodeDurationTimer = setInterval(() => {
      if(this.decodeDurationsPerSec.length > 0){
        const totalDuration = this.decodeDurationsPerSec.reduce((acc, curr) => acc + curr, 0)
        const avgDecodeDuration = totalDuration / this.decodeDurationsPerSec.length
        this.dispatch(updateMetrics({type: "decodeDuration", value: avgDecodeDuration.toFixed(2)}))
        this.decodeDurationsPerSec = []
      }
    }, 1000)
  }

  framesInQueueMeasurement(){
    this.framesInQueueTimer = setInterval(()=>{
      if (this.decoder && this.decoder.decodeQueueSize !== undefined) {
        this.dispatch(updateMetrics({ type: "framesInQueue", value: this.decoder.decodeQueueSize }));
      }
    }, 1000)
  }

  destroy() {
    this.decoder.close()
    this.decoder = null;
    cancelAnimationFrame(this.loopCancel);

    clearInterval(this.skippedFramesTimer)
    clearInterval(this.decodeDurationTimer)
    clearInterval(this.framesInQueueTimer)
  }
}

export const isRefFrame = (buffer) => {
  return buffer[4] === 0x67 || buffer[4] === 0x68 || buffer[4] === 0x40 || buffer[4] === 0x3F;
}

export default webcodec