import React from "react";
import {Breadcrumbs, Container, Divider, Link, Paper, Typography} from "@mui/material";

const PrivacyPolicy = () => {
    return (
        <Container sx={{mt: 12}} maxWidth={"lg"}>
            <div>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        href={"/privacy-policy"}
                    >
                        <Typography sx={{color: 'text.primary'}}>Privacy Policy</Typography>
                    </Link>
                </Breadcrumbs>
            </div>
            <Paper variant={"elevation"} type={"glass"} sx={{p: 8, my: 1, mt: 8}}>
                <Typography variant="h4" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Updated on Dec 15, 2024
                </Typography>
                <Divider sx={{my: 2}}/>
                <Typography variant="body1" paragraph>
                    Welcome to GameArena, a service provided by RhinoStream Technologies. Your privacy is critically
                    important to us,
                    and we are committed to protecting the personal information you share with us. This Privacy Policy
                    outlines how
                    we collect, use, disclose, and safeguard your information when you interact with our website or
                    services.
                </Typography>
                <Divider sx={{my: 2}}/>

                <Typography variant="h5" gutterBottom>
                    Collection of Your Personal Data
                </Typography>
                <Typography variant="body1" paragraph>
                    The types of Personal Data we collect about you depend on how you interact with us. “Personal Data”
                    refers to data that identifies, relates to, describes, or can be associated with you. The following
                    are categories and specific types of Personal Data we may collect about you.
                </Typography>
                <Typography variant='h5'>
                    How We Collect Your Personal Data
                </Typography>
                <Typography variant="body1">
                    We collect information that you voluntarily provide to us when you:
                </Typography>
                <ul>
                    <li>
                        <Typography variant='body1'>
                            <b>Create an Account</b>: During registration, you provide your first name, last name, and
                            email address to set up and personalize your account.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            <b>Contact Us</b>: When you reach out to our support team, provide feedback, or communicate
                            with us through any means, we may collect the information you provide, such as your email
                            address and details of your inquiry.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            <b>Opt-in to Communications</b>: When subscribing to newsletters or marketing updates, you
                            share your email address.
                        </Typography>
                    </li>
                </ul>
                <Typography variant='h6'>
                    Data from Google Login
                </Typography>
                <Typography variant="body1">
                    If you choose to log in to GameArena using your Google account, we may collect:
                </Typography>
                <ul>
                    <li>
                        <Typography variant='body1'>
                            First and Last Name
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            Email Address (to authenticate your account and communicate with you)
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            Profile Picture (if available, for account personalization)
                        </Typography>
                    </li>
                </ul>
                <Typography variant='body1'>
                    We only access the information that you explicitly authorize Google to share with us during the
                    login process.
                </Typography>
                <Typography variant='h6'>
                    Automatically Collected Data
                </Typography>
                <ul>
                    <li>
                        <Typography variant='body1'>
                            <b>Cookies and Tracking:</b>: For session management, user preferences, and analytics.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            <b>Log Files and Analytics:</b>: Information about your interactions with our website,
                            device type, IP address, and usage patterns.
                        </Typography>
                    </li>
                </ul>
                <Divider sx={{my: 2}}/>

                <Typography variant='h5'>
                    How We Use Your Information
                </Typography>
                <Typography variant="body1">
                    We use your personal information for the following purposes:
                </Typography>
                <ul>
                    <li>
                        <Typography variant='body1'>
                            To create and manage your account on GameArena.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            To send you service-related communications, such as confirmations, updates, and technical
                            notices.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            To respond to your inquiries or provide customer support.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            To send promotional materials or newsletters (if you opt-in).
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            To comply with legal and regulatory obligations.
                        </Typography>
                    </li>
                </ul>
                <Typography variant='body1'>
                    We do not sell, rent, or share your personal information with third parties for their direct
                    marketing purposes.
                </Typography>
                <Divider sx={{my: 2}}/>


                <Typography variant='h5'>
                    How We Protect Your Information
                </Typography>
                <Typography variant="body1">
                    We implement industry-standard security measures, including:
                </Typography>
                <ul>
                    <li>
                        <Typography variant='body1'>
                            <b>Encryption</b>: Data transmitted between your device and our servers is encrypted.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            <b>Secure APIs</b> : Google Login is integrated using OAuth 2.0, a secure authentication
                            protocol.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            <b>Access Controls</b>: Only authorized personnel can access user data.
                        </Typography>
                    </li>
                </ul>
                <Divider sx={{my: 2}}/>


                <Typography variant='h5'>
                    Data Sharing
                </Typography>
                <Typography variant="body1">
                    We do not sell or rent your personal information, including Google profile data, to third parties.
                    However, we may share your information in the following cases:
                </Typography>
                <ul>
                    <li>
                        <Typography variant='body1'>
                            <b>Service Providers</b>: Trusted partners who assist in hosting, analytics, or technical
                            support.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            <b>Legal Requirements</b>: If required by law, regulation, or legal process.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            <b>Mergers or Acquisitions</b>: In case of business restructuring, your data may be
                            transferred to the new entity.
                        </Typography>
                    </li>
                </ul>
                <Divider sx={{my: 2}}/>

                <Typography variant='h5'>
                    Your Rights
                </Typography>
                <Typography variant="body1">
                    For All Users you can:
                </Typography>
                <ul>
                    <li>
                        <Typography variant='body1'>
                            Manage your Google Login permissions through your Google account settings.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            Disconnect Google Login from GameArena by contacting us or revoking access in your Google
                            account.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='body1'>
                            Access, update, or delete your GameArena account data by contacting us at
                            support@gamearena.tech
                        </Typography>
                    </li>
                </ul>
                <Divider sx={{my: 2}}/>

                <Typography variant='h5'>
                    Data Retention
                </Typography>
                <Typography variant="body1">
                    We retain your public Google profile information only for as long as necessary to provide our
                    services. If you disconnect Google Login, we will securely delete your Google account data while
                    retaining only necessary account-related information.
                </Typography>
                <Divider sx={{my: 2}}/>
                <Typography variant='h5'>
                    Cookies
                </Typography>
                <Typography variant="body1">
                    Our website may use cookies to enhance your browsing experience and collect information about how
                    you interact with our site. You can adjust your browser settings to refuse cookies or alert you when
                    cookies are being sent, but some features of the site may not function properly without cookies.
                </Typography>
                <Divider sx={{my: 2}}/>


                <Typography variant='h5'>
                    Security and Retention
                </Typography>
                <Typography variant="body1">
                    We reserve the right to update or change this Privacy Policy at any time. Any changes will be posted
                    on this page, and the effective date will be updated accordingly. We encourage you to review this
                    Privacy Policy periodically for any updates.
                </Typography>
                <Divider sx={{my: 2}}/>


                <Typography variant='h5'>
                    Changes to This Privacy Policy
                </Typography>
                <Typography variant="body1">
                    If we make significant changes related to Google Login or other features, we will notify you by
                    email or through a notice on our website.
                </Typography>
                <Divider sx={{my: 2}}/>


                <Typography variant="h6" gutterBottom>
                    Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    For inquiries, contact:
                </Typography>
                <Typography variant="body2">
                    RhinoStream Technologies <br/>
                    Email: <Link href="mailto:support@gamearena.tech">support@gamearena.tech</Link> <br/>
                    Address: 235, 2nd & 3rd Floor, 13th Cross Rd, Indira Nagar II Stage, Hoysala Nagar, Indiranagar,
                    Bengaluru, Karnataka 560038
                </Typography>

                <Typography variant="body2" style={{marginTop: "20px", textAlign: "center"}}>
                    &copy; 2024 All Rights Reserved by RhinoStream Technologies
                </Typography>
            </Paper>
        </Container>
    );
};

export default PrivacyPolicy;
