import {
    Box,
    Button,
    Card,
    CardActions,
    CircularProgress,
    Container,
    Divider,
    Grid,
    InputAdornment,
    Link,
    styled,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {AccountCircleOutlined, KeyOutlined, SendOutlined,} from "@mui/icons-material";

import AppLogo from '../../images/GamearenaLogo.svg'
import {clearState, googleLoginThunk} from "../../redux/LoginSlice";
import {useDispatch, useSelector} from "react-redux";
import {Link as Rlink, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useMemo} from "react";
import {signupThunk} from "../../redux/SignupSlice";
import {GoogleLogin} from "@react-oauth/google";

const StyledContainer = styled(Container)(
    ({theme}) => ({
        alignSelf: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.spacing(4)} ${theme.spacing(4)} 0`
    })
)

const FormCard = (props) => {
    return <Card {...props} component={'form'}/>
}
const Signup = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.signup.loadingState === 'loading')

    const errors_raw = useSelector((state) => state.signup.errors)
    const navigate = useNavigate();
    const isSuccess = useSelector((state) => state.signup.loadingState === 'success')
    const [searchParams] = useSearchParams();
    const nextUrl = useMemo(() => {
        return searchParams.get("next") || "/";
    }, [searchParams]);

    useEffect(() => {
        if (isSuccess) {
            navigate("/login?next=" + nextUrl)
        }
    }, [isSuccess, navigate]);

    const errors = useMemo(() => {
        let errMap = {}
        for (const k in errors_raw) {
            errMap[k] = errors_raw[k].join(". ")
        }
        return errMap

    }, [errors_raw]);


    useEffect(() => {
        dispatch(clearState())
    }, []);


    const attemptSignup = (e) => {
        const formData = new FormData(e.target);
        let payload = Object.fromEntries(formData.entries())
        dispatch(signupThunk(payload));
        e.preventDefault();
    }
    return (<>
        <StyledContainer maxWidth={"sm"}
                         component={FormCard}
                         elevation={10}
                         variant={'elevation'} type={'glass'}
                         onSubmit={attemptSignup}
        >

            <Box sx={{display:'flex', justifyContent:'center'}}>
                <img style={{width:150 , height:'auto' }} src={AppLogo} alt={"logo"}/>
            </Box>
            <Box sx={{my: 2}}>
                <Typography sx={{my: 2}} variant={"h4"} color={'primary'}>Create a new account</Typography>
                <Typography variant={"h6"} color={'textSecondary'}>
                    to continue to <Typography variant={"h6"} component={'span'} color={'textPrimary'}>
                    Gamearena
                </Typography>
                </Typography>
            </Box>

            <Grid sx={{mt: 2}} container spacing={2}>
                <Grid sm={6} xs={12} item>
                    <TextField
                        fullWidth
                        disabled={isLoading}
                        autoFocus
                        sx={{}}
                        name={'first_name'}
                        label={"First name"}
                        type={"text"}
                        error={errors?.first_name}
                        helperText={errors?.first_name}
                    />
                </Grid>
                <Grid sm={6} xs={12} item>
                    <TextField
                        fullWidth
                        disabled={isLoading}
                        sx={{}}
                        name={'last_name'}

                        label={"Last name"}
                        type={"text"}
                        error={errors?.last_name}
                        helperText={errors?.last_name}
                    />
                </Grid>
                <Grid sm={12} xs={12} item>
                    <TextField
                        fullWidth
                        disabled={isLoading}
                        sx={{}}
                        name={'email'}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircleOutlined color={'primary'}/>
                                </InputAdornment>
                            )
                        }}
                        label={"Email"}
                        type={"email"}
                        error={errors?.email}
                        helperText={errors?.email}
                    />
                </Grid>
                <Grid sm={12} xs={12} item>
                    <TextField
                        fullWidth
                        disabled={isLoading}
                        sx={{}}
                        name={'password'}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <KeyOutlined color={'primary'}/>
                                </InputAdornment>
                            )
                        }}
                        label={"Password"}
                        type={"password"}
                        error={errors?.password}
                        helperText={errors?.password}
                    />
                </Grid>
                <Grid sm={12} xs={12} item>
                    <TextField
                        fullWidth
                        disabled={isLoading}
                        sx={{}}
                        name={'password_confirmation'}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <KeyOutlined color={'primary'}/>
                                </InputAdornment>
                            )
                        }}
                        label={"Confirm Password"}
                        type={"password"}
                        error={errors?.password_confirmation}
                        helperText={errors?.password_confirmation}
                    />
                </Grid>

            </Grid>

            <Typography
                sx={{mt: 2}}
                variant={'body1'} color={'textSecondary'}>Already have an account? <Link component={Rlink}
                                                                                         to={"/login"}>Login</Link></Typography>
            <Box sx={{my: 2, display: 'flex', justifyContent: 'center'}}>
                <GoogleLogin onSuccess={(creds) => {
                    dispatch(googleLoginThunk(creds))
                }} type={"standard"} theme={"filled_blue"} itp_support useOneTap use_fedcm_for_prompt
                             shape={"rectangular"}
                             text={"continue_with"}
                             onError={(error) => {
                                 console.error(error);
                             }}
                ></GoogleLogin>
            </Box>
            <Typography variant='body'>by creating an account, you agree to our <Link underline="hover" href="/terms-of-service" >Terms</Link> and have read and acknowledged the
                <Link underline="hover" href="/privacy-policy"> Privacy Policy</Link>
            </Typography>
            <Divider sx={{mt: 2}}/>
            <CardActions sx={{my: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Button
                    disabled={isLoading}
                    type={'submit'} endIcon={<SendOutlined/>} variant={"outlined"}>
                    {isLoading ? <CircularProgress size={24}/> : 'Signup'}
                </Button>
            </CardActions>
        </StyledContainer>
    </>)
}

export default Signup