import React from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    MenuItem,
    Select,
    Slider,
    TextField,
    Typography
} from "@mui/material";
import {ArrowDropDown} from "@mui/icons-material";

const SettingsCard = ({
                          title,
                          description,
                          settings,
                          menuType,
                          menuItems = [], // Default to an empty array
                          setSettingsValue,
                          sliderValue = 1, // Default slider value
                          scrollLimits = {min: 0, step: 1, max: 100} // Default scroll limits
                      }) => {
    if (!settings || settings.displayValue === undefined) {
        return null;
    }

    const handleSliderChange = (e, v) => {
        setSettingsValue(v);
    };

    const handleMenuItemClick = (e) => {
        setSettingsValue(e.target.value);
    };

    const handleTextChange = (e) => {
        setSettingsValue(e.target.value);
    };

    const handleCheckboxChange = (e) => {
        setSettingsValue(e.target.checked);
    };

    return (
        <Card    variant={'elevation'} elevation={3} type={'glass'}  sx={{
            display: 'flex', justifyContent: 'space-between', my: 1
        }}>
            <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '60%', ml: 2}}>
                <CardContent sx={{flex: '2 0 auto'}}>
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                    <Typography gutterBottom sx={{color: 'text.secondary', fontSize: 14}}>
                        {description}
                    </Typography>
                </CardContent>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', width: '40%'}}>
                <CardActions sx={{p: 2, mr: 2, display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                    {menuType === 'TEXT' ? (
                        <Box sx={{display: 'flex', alignItems: 'center', width: '160px', justifyContent: 'end'}}>
                            <TextField
                                variant="outlined"
                                value={settings.displayValue}
                                onChange={handleTextChange}
                            />
                        </Box>
                    ) : menuType === 'CHECKBOX' ? (
                        <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                            <Box sx={{flexGrow: 1}}/>
                            <Checkbox
                                checked={settings.displayValue}
                                onChange={handleCheckboxChange}
                            />
                        </Box>
                    ) : menuType === 'MENU' ? (
                        <Select
                            sx={{display: 'flex', alignItems: 'center', width: '160px'}}
                            value={settings.displayValue}
                            onChange={handleMenuItemClick}>
                            {menuItems.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : menuType === 'SLIDER' ? (
                        <>
                            <Select
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                                variant="outlined"
                                value={settings.displayValue}
                                sx={{width: '160px'}}
                                renderValue={(selected) => {
                                    return selected
                                }}
                            >
                                <Box sx={{alignItems: 'center', width: '400px', px:1}}>
                                    <Slider
                                        onChange={handleSliderChange}
                                        valueLabelDisplay="auto"
                                        min={scrollLimits.min}
                                        step={scrollLimits.step}
                                        max={scrollLimits.max}
                                        value={sliderValue || 1}
                                    />
                                </Box>
                            </Select>
                        </>

                    ) : null}
                </CardActions>
            </Box>
        </Card>
    );
};

export default SettingsCard;
