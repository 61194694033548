class Scream {
  descriptions = {};

  add_description(buf) {
    let jsonText = new TextDecoder().decode(buf);
    let desc = JSON.parse(jsonText);
    console.log(desc)
    this.descriptions[desc.typeId] = desc;
  }

  parse(buf) {
    let view = new DataView(buf);
    let size = view.getUint32(0, true);
    if (view.byteLength !== size) {
      throw new Error("Invalid serialization, size " + size + " byteLength " + view.byteLength);
    }
    let typeId = view.getUint8(4)
    if (!this.descriptions[typeId]) {
      return null;
    }
    let desc = this.descriptions[typeId];

    let offset = 5;
    let output = {}
    for (let i = 0, len = desc.layout.length; i < len; i++) {
      let layout = desc.layout[i];
      switch (layout.kind) {
        case "uint16":
          output[layout.label] = view.getUint16(offset, true)
          offset += 2
          break;
        case "int16":
          output[layout.label] = view.getInt16(offset, true)
          offset += 2
          break;
        case "uint32":
          output[layout.label] = view.getUint32(offset, true)
          offset += 4
          break;
        case "int32":
          output[layout.label] = view.getInt32(offset, true)
          offset += 4
          break;
        case "int64":
          output[layout.label] = view.getBigInt64(offset, true)
          offset += 8
          break;
        case "uint64":
          output[layout.label] = view.getBigUint64(offset, true)
          offset += 8
          break;
        case "float32":
          output[layout.label] = view.getFloat32(offset, true)
          offset += 4
          break;
        case "float64":
          output[layout.label] = view.getFloat64(offset, true)
          offset += 8
          break;
        case "byte":
          output[layout.label] = view.getUint8(offset)
          offset += 1
          break;
        case "bool":
          output[layout.label] = view.getUint8(offset) === 1
          offset += 1
          break;
        case "bytes":
        case "string":
          let aSize = view.getUint32(offset, true)
          offset += 4
          let content = new Uint8Array(buf, offset, aSize);
          if (layout.kind === "string") {
            output[layout.label] = new TextDecoder().decode(content);
          } else {
            output[layout.label] = content
          }
          offset += aSize
          break;
      }
    }
    return output;
  }
}

export default Scream;