import React from 'react';
import {Button, Box, Typography, Container, styled, Card, Paper} from '@mui/material';
import ErrorBoundaryImage from '../../images/ErrorBoundaryImage.png'


const FullBackground = styled('div', {
    name: 'ErrorBoundaryBackground', slot: 'background'
})(({theme}) => ({
    backgroundSize: 'cover',
    background: 'radial-gradient( circle at 70vw 30vh, rgba(19,47,74,1) 0%, rgba(0,0,0,1) 60%)',
    backgroundRepeat: 'no-repeat',
    position: 'fixed',
    display: 'block',
    height: "100%",
    width: "100%",
    zIndex: -1,
    overflow: 'hidden',
}))

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }



    componentDidCatch(error, errorInfo) {
        console.error("Error caught by ErrorBoundary: ", error, errorInfo);
    }

    handleRefresh = () => {
        window.location.reload();
    };

    render() {

        if (this.state.hasError) {
            return (
               <>
                    <FullBackground />

                    <Container
                        maxWidth="sm"
                        sx={{
                            textAlign: 'center',
                            padding: 2,
                            pt:4,
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >

                    <Paper elevation={10}
                           variant={'elevation'} type={'glass'} sx={{p:4}} >
                        <Typography
                            variant="h4"
                            gutterBottom
                            sx={{
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: 'bold',
                                color: '#eaeaea',
                            }}
                        >
                            Oops! Something went wrong.
                        </Typography>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                fontFamily: 'Roboto, sans-serif',
                                fontStyle: 'italic',
                                color: '#cbcbcb',
                            }}
                        >
                            "Looks like we've hit a bug... or maybe a boss battle!"
                        </Typography>
                        <Box
                            component="img"
                            src={ErrorBoundaryImage}
                            sx={{
                                maxWidth: '60%',
                                height: 'auto',
                                my: 1,
                                borderRadius: 8,
                            }}
                        />
                        <Typography
                            variant="body1"
                            sx={{
                                marginBottom: 2,
                                color: '#cacaca',
                                fontFamily: 'Roboto, sans-serif',
                            }}
                        >
                            Don't worry, we're respawning the page. In the meantime, feel free
                            to refresh or head back to the home screen.
                        </Typography>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ marginRight: '1rem' }}
                                onClick={this.handleRefresh}
                            >
                                Refresh Page
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                href="/"
                            >
                                Go to Home
                            </Button>
                        </Box>
                    </Paper>
                </Container>
                </>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
